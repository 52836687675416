import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import Locations from "../components/locations"

const LocationsInNyc = () => (
  <Layout>
    <SEO title="Locations | Service Areas" />
    <DetailsHead title="Service Areas"/>
    <Locations/>
  </Layout>
)

export default LocationsInNyc
