import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"

import styles from './locations.module.css'
import LocationPin from '../../images/icon/location.svg'

import EmailForm from './../contact/email-form'
import GoogleMapReact from 'google-map-react';

const Locations = () => {

	const query = useStaticQuery(graphql`
	query {
      allStrapiLocations{
          edges{
            node{
              name,
              slug
            }
          }
        }
	  }
	`)

	let p = [];
	query.allStrapiLocations.edges.forEach(({node})=>{
		p.push(node)
	})

	console.log(p)

	function handleApiLoaded(map, maps){
		// map = map
		// maps = maps
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.wrapperLeft}>
						<div className={styles.bannerWrapper}>
							<h4>Car locksmith services in:</h4>
							<p>we are at your service as quickly as possible and at any hour. The child is locked in a home or vehicle. Lost your car keys? The door of the house door is broken and you cannot open the door. We are at your service. Call now</p>
						</div>

						<div className={styles.locationWrapper}>
						{
							p.map((location, index)=>{
								return (
									<div>
										<Link to={`/location/${location.slug}`}>
										<div className={styles.location}>
											<img src={LocationPin} />
											<h4>{location.name}</h4>
										</div>
										</Link>
									</div>
									)
								})
							}
						
					 	</div>

					</div>
					<div className={styles.wrapperRight}>
						<h4>Contact us</h4>
						<EmailForm styles={styles}/>
					</div>
				</div>
			</div>
	      <div className={styles.mapContactContainer}>
	        <GoogleMapReact
	          bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
	          defaultCenter={{
			      lat: 40.631605,
			      lng: -74.033077
			    }}
	          defaultZoom={11}
	          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
	        >
	        </GoogleMapReact>
	      </div>
		</>
	)
}

export default Locations